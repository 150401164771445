<template>
  <div>
    <header-slot />
    <Commissions :module="moduleId" tab="3" />
  </div>
</template>

<script>
import Commissions from "@/views/commons/components/commissions/CommissionsComponent.vue";

export default {
  components: { Commissions },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>